<template>
  <div class="bg max-w1200 mrauto mt47 mb51 pd20">
    <div class="pd40 bg-ffffff bor-E6E6E6">
      <div class="fs-26 fwb col-CC0000">
        使用微信扫码进入小程序：
      </div>
      <div class="mrauto wh400 mt20">
        <img src="../../assets/img/xiaochegnxu.jpg" alt="" class="wh400" />
      </div>
    </div>
  </div>
</template>

<script>
import { mer_info } from "../../utils/api.js";
export default {
  data() {
    return {
      navIndex: "1",
      content: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    select(e) {
      this.navIndex = e;
      this.getInfo();
    },
    getInfo() {
      let that = this;
      mer_info({
        type: this.navIndex,
      })
        .then((res) => {
          console.log(res);
          that.content = res.data
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }
.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}
</style>
